<template>
  <div>
    <h1>Page not found</h1>
  </div>
</template>

<script>
export default {
    name: 'NotFoundView',
}
</script>

<style lang="scss" scoped>
div {
    text-align: center;
}
</style>
